/*
* @Author: Evouqe Innovative Lab
* @Date:   2021-11-16 17:35:19
* @Last Modified time: 2021-11-12 18:32:23
*/

@import "./../config.scss";

.e_fund_card {
  .e_inner_card {
    background-color: $white;
    border-radius: 8px;
    // height: auto;
    // box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
    .e_logo {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }
    .e_add {
      width: 32px;
      border: 1px solid $primary_color;
      box-sizing: border-box;
      border-radius: 8px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s all ease-in-out;
      cursor: pointer;
      float: right;
      position: relative;
      top: -1rem;
      margin-right: 12px;
    }
    .e_add:hover {
      transform: scale(1.1);
    }
    .e_remove {
      width: 32px;
      background-color: $primary_color;
      box-sizing: border-box;
      border-radius: 8px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-top:-15px;
      float: right;
      position: relative;
      top: -1rem;
      margin-right: 12px;
    }

    .e_inner_card_title {
      color: $dark_grey;
      @extend %font14;
      line-height: 150%;
      font-weight: 600 !important;
      min-height: 60px;
    }

    label {
      color: $light_grey;
    }
    .e_returns {
      color: $dark_grey;
      @extend %font12;
      font-weight: 700;
      span {
        font-weight: 400 !important;
      }
    }
  }
  .e_scheme_name {
    color: $dark_grey;
    @extend %font14;
    line-height: 150%;
    padding: 8px 16px;
    font-weight: 600 !important;
    // min-height: 60px;
  }
  .e_selected_scheme {
    color: $dark_grey;
    @extend %font14;
    line-height: 150%;
    font-weight: 600 !important;
    background: #f5f6ff;
    border-radius: 8px;
    padding: 14px 16px;
  }
}
