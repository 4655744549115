@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    Admin Template
Version:    1.0.0
Created:    08-11-2021
Use:
-------------------------------------------------------------------*/

/*--- 1. Import Fonts ----*/

$font-primary: "Poppins";
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Poppins/Poppins-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Poppins/Poppins-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Poppins/Poppins-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Poppins/Poppins-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Poppins/Poppins-Light.ttf);
  font-weight: 300;
}

$font-secondary: "Inter";
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Inter/Inter-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Inter/Inter-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Inter/Inter-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Inter/Inter-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Inter/Inter-Light.ttf);
  font-weight: 300;
}

$font-ternary: "Lexend";
@font-face {
  font-family: $font-ternary;
  src: url(../assets/fonts/Lexend/Lexend-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: $font-ternary;
  src: url(../assets/fonts/Lexend/Lexend-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: $font-ternary;
  src: url(../assets/fonts/Lexend/Lexend-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: $font-ternary;
  src: url(../assets/fonts/Lexend/Lexend-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: $font-ternary;
  src: url(../assets/fonts/Lexend/Lexend-Light.ttf);
  font-weight: 300;
}

/* --- 2. Colors --- */
$primary_color: #106ee8;
$primary_dark: #1367d4;
$grey: #2d2d2d;
$header_bg: #4f7097;
$card_title: #5a596b;

$secondary_color: #c5c5c5;
$dark_green: #2e6a0f;
$body_bg: #f6f9ff;
$hover_color: #0b1fca;
$white: #ffffff;
$black: #000000;
$dark_grey: #010101;
$secondary_btn_hover: #edf3ff;
$secondary_hover_border: #0b1fca;
$light_grey: #6d6d6d;
$tab_color: #bebebe;
$card_bg: #f2f6f6;
$toast_success_border: #00d1b1;
$toast_success_bg: #d4ece8f5;
$toast_success_color: #089487;
$toast_fail_bg: #f9e5e5;
$input_boder: #d2d2d2;
$input_placeholder: #9ea3aa;
$error: #ff523b;
$accordion_bg: #f0f0f0;
$delete: #ef4a3c;
$badge_fail: #de2020;
$footer_bg: #f6f9ff;
$green: #16a377;
$orange: #ffb61d;
$red: #ff0000;
$off_white: #f3f3f3;
$light_green: rgba(22, 163, 119, 0.1);
$search_bg: #f3f3f3;
$notification_boder: #f0f0f0;
$secondary_grey: #4f4f4f;
$download_bg: #edf3ff;
$profile_bg: #edf3ff;
$status_bg: #0fd99c;
$tooltip_color: #eef2f5;
$table_row: #f1f7f2;
$accordion_body_bg: #dbdbdb;
$toast_color: #f1f1f1;
$warning_color: #ffc300;
$transaction_bg: #fafafa;
$cornflower-blue: #4e97d6;

/* --- 3. Font --- */
%font10 {
  font-size: 0.625rem;
}
%font11 {
  font-size: 0.688rem;
}
%font12 {
  font-size: 0.75rem;
}
%font14 {
  font-size: 0.875rem;
}
%font16 {
  font-size: 1rem;
}
%font18 {
  font-size: 1.125rem;
}
%font20 {
  font-size: 1.25rem;
}
%font24 {
  font-size: 1.5rem;
}
%font32 {
  font-size: 2rem;
}
%font36 {
  font-size: 2.25rem;
}
%font48 {
  font-size: 3rem;
}

/* --- 2. Global --- */
%heading {
  color: $dark_grey;
  font-weight: 600 !important;
  -webkit-font-smoothing: antialiased;
}
%h3 {
  @extend %font20;
}
%h4 {
  color: $dark_grey;
  @extend %font24;
  font-weight: 700;
  line-height: 120%;
  -webkit-font-smoothing: antialiased;
}
%h6 {
  color: $dark_grey;
  @extend %font14;
  line-height: 17px;
  font-weight: 600 !important;
}
.h-100 {
  height: 100% !important;
}
.cursor-pointer {
  cursor: pointer;
}
.modal-dialog {
  max-width: 696px !important;
  // height: 556px!important;
}
.modal-content {
  padding: 15px !important;
  border-radius: 8px !important;
}
.modal-header {
  border-bottom: none !important;
}
.btn-close {
  opacity: 10 !important;
}
button:focus:not(:focus-visible) {
  outline: none !important;
}
.btn-close:focus {
  box-shadow: none !important;
}
.btn-close:hover {
  transform: scale(1.1);
}

/* ---3. Mixin styles ---*/

// primary button
@mixin hover_animation() {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all 0.35s;
  z-index: 1;
  opacity: 0;
}

/* ---4. variable ---*/
