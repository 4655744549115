/*
 *   File : tab.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Tab style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_tab {
	@extend %font14;
	font-weight: 500;
	line-height: 120%;
	color: $dark_grey;
	transition: ease 0.7s;
	position: relative;
	background-color: $white;
	padding: 10px 16px;
	border-radius: 8px;
	border: 1px solid $accordion_bg;
	margin-right: 0.5rem;
	&:hover {
		cursor: pointer;
		background-color: $primary_color;
		color: $white;
		opacity: 0.8;
		border-color: transparent;
		transition: ease 0.7s;
	}
}
.e_active_tab {
	@extend .e_tab;
	transition: ease 0.3s;
	opacity: 1;
	background-color: $primary_color;
	color: $white;
	border-radius: 8px;
	margin-right: 0.5rem;
	border-color: transparent;
}
