@import "../config.scss";

.e_maturity_wrapper {
    background: #ffffff;
    border: 1px solid $accordion_body_bg;
    padding: 16px;
    margin-bottom: 24px;
    border-radius: 8px;
    .e_title {
        font-weight: 600;
        @extend %font14;
        line-height: 20px;
        color: $dark_grey;
        opacity: 0.87;
    }
    .e_sub_title {
        color: $light_grey;
        font-weight: 400;
        @extend %font14;
    }
    .e_input {
        pointer-events: none;
    }
    .e_edit {
        font-weight: 600;
        @extend %font14;
        color: $primary_color;
    }
    .e_delete {
        color: $red;
        @extend .e_edit;
    }
}
