/*
 *   File : acccordion.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_faq_accordion {
    .e_accordion_button {
        border: none !important;
        background-color: $white;
        text-align: left;
        width: 100%;
        position: relative;
        border-radius: 0;
        outline: none;
        //  &[aria-expanded="false"]{
        .e_accordion_icon {
            position: absolute;
            top: 35%;
            right: 0px;
            background-image: url(../../assets/images/manage-content/down-arrow.svg);
            background-repeat: no-repeat;
            padding: 13px;
            transition: 0.5s ease-in-out;
        }
        //  }
        //  &[aria-expanded="true"]{
        .e_accordion_icon_minus {
            position: absolute;
            top: 35%;
            right: 0px;
            background-image: url(../../assets/images/manage-content/up-arrow.svg);
            background-repeat: no-repeat;
            padding: 13px;
            transition: 0.5s ease-in-out;
        }
        //  }
    }
    .e_accordion_item {
        border: none !important;
        padding: 10px 0px !important;
    }
    .e_faq_accordion_header {
        line-height: 18px;
    }
    .e_accordion_title {
        font-weight: 600;
        @extend %font14;
        line-height: 20px;
        color: $black;
        font-family: $font-secondary;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .e_accordion_wrapper {
        background: #f5f7fd;
        border-radius: 16px;
        padding: 14px;
        .e_accordion_content {
            font-weight: 300;
            @extend %font14;
            line-height: 150%;
            color: #242631;
            font-family: $font-ternary;
        }
    }
    .e_delete {
        color: #de2020;
        font-weight: 700;
        display: inline-flex;
        &:hover {
            //  opacity: 0.7;
        }
    }
    .e_edit {
        font-weight: 700;
        display: inline-flex;
        &:hover {
            //  opacity: 0.7;
        }
    }
    .e_toggle_label {
        font-weight: 600;
        @extend %font14;
        line-height: 21px;
        color: $primary_color;
        padding-left: 8px;
    }
}
.e_accordion_body {
    padding: 0 0 !important;
}
