@import "./../config.scss";

.e_multi_select {
    input {
        @extend %font12;
        background: $white;
        border: 0.5px solid $light_grey !important;
        outline: none;
        width: 100%;
        font-weight: 400;
        color: $dark_grey;
        height: 38px;
        border-radius: 4px;
        padding-left: 12px;
        position: relative;
        cursor: pointer;
        &::placeholder {
            font-weight: 400;
            line-height: 18px;
            color: $dark_grey;
            opacity: 0.27;
        }
        &:focus {
            border: 0.5px solid $primary_color;
        }
    }
    .e_dropdown {
        top: 48px;
        background: $white;
        border-radius: 8px;
        width: 100%;
        z-index: 10;
        overflow: hidden;
        box-shadow: 0px 0px 2px 0px #00000036;
        .e_dropdown_wrapper {
            height: 200px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 4px !important;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $primary_color !important; // Assuming $light_lavender is a SCSS variable
                border-radius: 0.5rem !important;
            }
        }
        p {
            color: $dark_grey;
            @extend %font14;
            line-height: 18px;
            font-weight: 500;
        }
    }
    .e_dropdown_value {
        top: 6px;
        left: 16px;
        display: -webkit-box;
        white-space: normal !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        span {
            font-weight: 500;
            @extend %font12;
            line-height: 18px;
            color: $dark_grey;
            opacity: 0.87;
            padding-bottom: 4px;
        }
    }
}
