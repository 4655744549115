/*
 *   File : userDetail.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : userDetail.module.scss
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_user_detail_card {
  background-color: $white;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
  border-radius: 8px !important;
  padding: 22px 24px 22px 24px !important;
  transition: box-shadow 0.3s;
  z-index: 0;
  border: none !important;
  outline: none;
  .e_profile_img {
    @extend %font32;
    background-color: #d2d5ea;
    height: 120px;
    width: 120px;
    color: $primary_color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }
  .e_accordion_body_wrapper {
    background: #f9f5ff;
    border-radius: 8px;
    padding: 16px;
    h6 {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #6d6d6d;
    }
    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #2d2d2d;
    }
  }
  .e_detail_sec {
    background-color: $download_bg;
    padding: 20px 30px;
    // height: 92px;
    margin: 10px 0px;
    border-radius: 8px;
  }
  .e_detail_title {
    font-family: $font-primary;
    font-weight: 500;
    @extend %font12;
    color: $light_grey;
  }
  .e_detail_sub_title {
    font-family: $font-primary;
    font-weight: 600;
    @extend %font12;
    line-height: 17px;
    color: #2d2d2d;
    word-break: break-all;
    width: 80%;
  }
  .e_user_icon {
    width: 120px;
    height: 120px;
  }
  h4 {
    @extend %h4;
    font-family: $font-primary;
  }
  .e_span {
    padding: 14px 24px;
  }
  p {
    @extend %font18;
    line-height: 24px;
    color: $light_grey;
    font-weight: 600;
  }
  .e_target {
    margin-right: 10px;
  }
}
