/*
 *   File : otheMenu.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Other Menu Style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_notification_menu {
  .dropdown {
    position: relative;
  }
  .e_notification_div {
    position: absolute;
    background-color: $white;
    min-width: 420px;
    height: max-content;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
    z-index: 3;
    right: 70px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    top: 15px;
    padding: 15px 15px 0;
    text-align: left !important;
    .e_title {
      font-weight: 500;
      @extend %font12;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $light_grey;
    }
    .e_notification_sub_data {
      height: 250px;
      overflow-y: scroll;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    .e_span_border {
      border-top: 1px solid $notification_boder;
      display: block;
    }
    .e_sub_title {
      font-weight: 400;
      @extend %font12;
      line-height: 15px;
      text-transform: uppercase;
      color: $secondary_grey;
      text-align: left;
    }
    .e_notification_title {
      font-weight: 700;
      @extend %font14;
      color: $dark_grey;
    }
    .e_notification_content {
      font-weight: 400;
      @extend %font14;
      line-height: 22px;
      color: $light_grey;
      overflow-y: scroll;
    }
    .e_notification_old_title {
      font-weight: 600;
      @extend %font16;
      line-height: 20px;
      color: $secondary_grey;
      padding-top: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .e_view {
    @extend %font14;
    line-height: 24px;
    font-weight: 700;
    color: $primary_color;
    text-align: center;
  }
}
