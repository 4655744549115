/*
 *   File : acccordion.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_user_accordion {
  .e_accordion_sec {
    border: 1px solid $accordion_body_bg;
    border-radius: 8px;
    width: 100%;
    padding: 0px 10px 14px;
    margin-bottom: 20px;
  }
  .e_accordion_body_tab {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 0.2px;
      width: 100%;
      bottom: 1px;
      background-color: #dbdbdb;
    }
  }
  .e_add_sec {
    border: 1px solid $accordion_body_bg;
    border-radius: 8px;
    width: 100%;
    padding: 7px 10px 14px;
    margin-bottom: 20px;

    h6 {
      color: $primary_color !important;
      @extend %font14;
      line-height: 24px;
      font-weight: 700 !important;
      padding: 12px 10px 0px;
      width: 100%;
      cursor: pointer;
      &:hover {
        translate: scale(1.5);
      }
    }
  }

  .e_accordion_button {
    border: none !important;
    background-color: $white;
    text-align: left;
    width: 100%;
    position: relative;
    border-radius: 0;
    outline: none;
    // &[aria-expanded="false"] {
    .e_accordion_icon {
      position: absolute;
      top: 35%;
      right: 0px;
      background-image: url(../../assets/images/dataFormating/add-blue.svg);
      background-repeat: no-repeat;
      padding: 13px;
      transition: 0.5s ease-in-out;
    }
    // }
    // &[aria-expanded="true"] {
    .e_accordion_icon_minus {
      position: absolute;
      top: 35%;
      right: 0px;
      background-image: url(../../assets/images/dataFormating/minus-blue.svg);
      background-repeat: no-repeat;
      padding: 13px;
      transition: 0.5s ease-in-out;
    }
    // }
  }
  .e_accordion_item {
    border: none !important;
    .e_accordion_header {
      background: $white;
    }
  }
  .e_accordion_title {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #2d2d2d;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .e_accordion_sub_title {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #2d2d2d;
  }

  .e_accordion_wrapper {
    background: $white;
    border-radius: 8px;
    padding: 10px;
    margin: 2px;

    .e_accordion_content_img {
      width: 4%;
    }
    .e_accordion_content {
      font-weight: 400;
      @extend %font14;
      line-height: 150%;
      color: $dark_grey;
      h6 {
        @extend %font14;
        font-weight: 600;
        padding-top: 15px;
      }
    }
    .e_accordion_body_wrapper_value {
      background: #fff5f7;
    }
    .e_accordion_body_wrapper {
      background: #f9f5ff;
      border-radius: 8px;
      padding: 16px;
      h6 {
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #6d6d6d;
      }
      p {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #2d2d2d;
      }
    }
    .e_accordion_body_wrapper_value {
      background: #fff5f7;
    }
    .e_investment_wrapper {
      background: #f5f6ff;
      border-radius: 8px;
      padding: 16px 32px;
      h6 {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #2d2d2d;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #2d2d2d;
      }
    }
  }
}

.e_accordion_body {
  padding: 0 0 !important;
}
