/*
 *   File : selectBox.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : SelectBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-11-2021
 */

@import "./../config.scss";

.css-13cymwt-control {
  border: 0.5px solid $input_boder !important;
  border-radius: 8px !important;
  color: $dark_grey;
  min-height: 44px !important;
  background-color: $white;
  &:hover {
    cursor: pointer;
  }
}
.css-14el2xx-placeholder {
  font-weight: 400 !important;
  @extend %font14;
  color: #333333;
  // opacity: 0.27;
}

// .css-tj5bde-Svg {
//   display: inline-block;
//   fill: transparent !important;
//   line-height: 1;
//   stroke: currentColor;
//   stroke-width: 0;
// }

.css-tj5bde-Svg,
.css-8mmkcg {
  background-repeat: no-repeat;
}
.css-1insrsq-control {
  background: #ffffff !important;
  border: 0.5px solid #d2d2d2;
  outline: none;
  width: 100%;
  font-weight: 400;
  color: hsl(0, 0%, 0%);
  // padding: 7px 6px;
  border-radius: 8px !important;
  min-height: 20px !important;
}

.css-1dimb5e-singleValue,
.css-lr9fot-singleValue {
  @extend %font12;
  font-weight: 500 !important;
}
.css-1nmdiq5-menu,
.css-1n6sfyn-MenuList {
  @extend %font12;
  font-weight: 500;
}
.css-t3ipsp-control {
  box-shadow: none !important;
  border: 0.5px solid $input_boder !important;
  border-radius: 8px !important;
  min-height: 44px !important;

  &:hover {
    box-shadow: none !important;
    border: 0.5px solid $input_boder !important;
    border-radius: 8px !important;
  }
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}
.css-1xc3v61-indicatorContainer {
  color: $dark_grey !important;
}
.css-1gtu0rj-indicatorContainer {
  color: $dark_grey !important;
  &:hover {
    color: $dark_grey !important;
    cursor: pointer;
  }
}
.e-sort {
  z-index: 5;
  .css-13cymwt-control {
    border: 0.5px solid $light_grey !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    color: $dark_grey;
    background-color: transparent;
    min-height: 20px !important;
    background-color: $white;
  }
  .css-t3ipsp-control {
    border: 0.5px solid !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    color: $dark_grey;
    background-color: transparent;
    min-height: 20px !important;
    background-color: $white;
  }
  .css-qc6sy-singleValue {
    @extend %font12;
    font-weight: 500 !important;
  }
  .css-4ljt47-MenuList {
    @extend %font12;
    font-weight: 500;
  }
}
