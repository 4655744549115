/*
 *   File : footer.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : More Button Style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_footer {
  background: $footer_bg;
  padding: 80px 50px;
  .e_footer_logo {
    width: 72px;
    height: 24px;
    &:hover {
      cursor: pointer;
    }
  }
  .e_footer_left {
    font-weight: 400;
    @extend %font14;
    line-height: 160%;
    color: $light_grey;
    padding-left: 10px;
  }
  .e_social_wrap {
    .e_link a {
      font-weight: 400;
      @extend %font14;
      line-height: 160%;
      color: $dark_grey;
      text-decoration: none;
      // margin-right: 48px;
      &:hover {
        color: $primary_color;
        text-decoration: underline;
        transition: 0.3s ease-in-out;
      }
    }
  }
  .e_footer_company_link {
    color: $primary_color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      transition: 0.3s ease-in-out;
    }
  }
  .e_footer_label {
    color: $light_grey;
    @extend %font14;
  }
  .e_footer_powered {
    color: $primary_color;
    @extend %font14;
  }
}
