/*
 *   File : confirmation.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";
.e_approve {
  .e_modal_dialog {
    width: 378px;
    height: 238px;
  }
  .e_modal_content {
    border-radius: 36px !important;
    padding-bottom: 40px !important;
  }
  .e_notification_modal_content {
    border-radius: 36px !important;
    padding: 36px 12px !important;
  }
  h6 {
    @extend %h6;
    font-weight: 600 !important;
    line-height: 17px;
    color: $status_bg;
    margin-top: 24px;
    text-align: center;
  }
  p {
    @extend %font14;
    color: $black;
    font-weight: 400;
    text-align: center;
  }
  .e_search_funds_txt {
    font-weight: 600;
    @extend %font14;
    line-height: 130%;
    color: $light_grey;
    padding-top: 8px;
  }
  .e_approve_loader {
    width: 200px;
  }
  .e_failed_txt {
    @extend %h6;
    font-weight: 600 !important;
    line-height: 17px;
    color: red;
    margin-top: 24px;
    text-align: center;
  }
  .e_failed_message {
    @extend %font14;
    color: red;
    font-weight: 400;
    text-align: center;
  }
}
