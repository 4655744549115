/*
 *   File : transactionDetail.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Trnsaction detail style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_transaction_detail {
  .e_dialog {
    // max-width: 1140px !important;
  }
  .e_modal_content {
    // background-color: $white;
    // width: 696px;
    // height: 344px;
    // border-radius: 8px;

    .e_modal_title {
      // @extend %h6;
      // font-weight: 700 !important;
      // @extend %font14;
      // line-height: 130%;
      // color: $dark_grey;
    }
    h6 {
      @extend %h6;
    }
    .e_tooltip {
      left: 0;
      top: 2rem !important;
    }
    .e_transaction_content {
      background-color: $transaction_bg;
      .e_time_date {
        font-weight: 400 !important;
        @extend %h6;
        line-height: 17px;
        color: $dark_grey;
      }
    }
    .e_transaction_id {
      @extend %font14;
      color: $dark_grey;
      font-weight: 400;
      line-height: 17px;
    }
    .e_badge {
      @extend %font14;
    }
    .e_span {
      h6 {
        font-weight: 600;
      }
      p {
        font-weight: 400;
        @extend %h6;
        line-height: 17px;
        color: $dark_grey;
      }
      span {
        font-weight: 400;
        @extend %font14;
        line-height: 17px;
        color: $dark_grey;
      }
    }
    .e_details {
      h6 {
        @extend %h6;
        line-height: 17px;
        font-weight: 600;
        color: $card_title;
      }
      p {
        padding-top: 8px;
        font-weight: 400 !important;
        color: $dark_grey;
      }
    }
    .e_icon {
      background-color: $orange;
      border-radius: 100%;
      min-width: 48px;
      min-height: 48px;
      text-align: center;
      color: $white;
    }
    .btn-close {
      border: solid 1px blue;
    }
  }
}
